/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.cb-home {
  width: 33.03834808%;
  max-width: 259px;
  margin-top: 84px;
  left: 0;
}
.desk {
  width: 88.28125%;
  max-width: 890px;
}
.navigation > .desk {
  width: 100%;
  text-align: center;
  vertical-align: top;
}
.header-tel {
  display: block;
  float: left;
  margin-top: 13px;
}
.cb-cart-icon-holder {
  float: right;
  margin-top: 16px;
}
.cb-mood--around {
  margin-top: 110px;
}
.cb-pages {
  position: relative;
}
.cb-pages:after {
  /*
prevent .shopright from peeking through above mood
*/
  position: absolute;
  right: 0;
  top: -190px;
  height: 190px;
  width: 50px;
  content: '';
  background: #fff;
  z-index: 4;
}
.cb-mood > .desk {
  position: relative;
}
.cb-page-title {
  margin-top: 40px;
}
.cb-page-layout1 h1 {
  font-size: 28px;
  font-size: 2.8rem;
}
.cb-page-layout2 .cb-page-title {
  margin-top: 38px;
}
.cb-mood--around {
  z-index: 3;
}
.scroll-to-content {
  position: relative;
}
.scroll-to-content:after {
  content: '';
  width: 50px;
  height: 12px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.6)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
  background-image: linear-gradient(top, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
}
.shopright,
.shopright > .meta {
  width: 42px;
  height: 147px;
  background: url(/images/service_shop-2016-04-07.png) no-repeat;
}
.shopright > .cart {
  background-image: url(/images/service_cart-2016-04-07.png);
}
.cb-page-first .shopright {
  /*
top: at least more than .navigation
*/
  position: fixed;
  z-index: 2;
  bottom: 50%;
  right: 0;
  display: block;
}
@media (max-height: 560px) {
  .cb-page-first .shopright {
    bottom: 80px;
  }
}
.shopright > .meta {
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  transition: background 0.218s;
}
.shopright > .meta:hover,
.shopright > .meta:focus {
  opacity: 1;
  -webkit-transition: none;
  transition: none;
}
.shopright > .service_shop:hover,
.shopright > .service_shop:focus {
  background-image: url(/images/service_shop--hover-2016-04-07.png);
}
.shopright > .cart:hover,
.shopright > .cart:focus {
  background-image: url(/images/service_cart--hover-2016-04-07.png);
}
#external,
#external > .meta {
  max-width: none;
  float: none;
}
#external {
  text-align: justify;
  margin: 56px auto 18px;
}
#external:after {
  width: 90%;
  display: inline-block;
  content: '';
}
.service_external_ewb {
  width: 194px;
}
.cb-page-selected-sub1 .service_external_ewb,
.cb-page-selected-sub2 .service_external_ewb,
.cb-page-selected-sub3 .service_external_ewb {
  background-image: url(/images/external_ewb-medium-194.png);
}
.service_external_ewb:hover,
.service_external_ewb:focus,
.aside:hover .service_external_ewb:after {
  background-image: url(/images/external_ewb---hover-medium-194.png);
}
.footer {
  text-align: left;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.33333333;
}
.confiserie-eichenberger,
.artos-login {
  /*
(1)
align according to .confiserie-eichenberger fontsize
*/
  font-size: 12px;
  font-size: 1.2rem;
}
.confiserie-eichenberger {
  display: inline-block;
}
.vcard-h2 {
  /* 
has to look like footer sub1 menu
*/
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.33333333;
  text-transform: uppercase;
  color: #ccc;
  display: block;
  color: #ccc !important;
}
.vcard-h3 {
  margin-top: 10px;
}
.copyright {
  margin-top: 86px;
  padding: 30px 0 12px;
}
.artos-login {
  float: right;
  margin-top: 0;
  white-space: nowrap;
}
.service_artos,
.artos-login,
.artos-login > .auth {
  font-size: 10px;
  font-size: 1rem;
}
.service_artos,
.meta.firm {
  margin: 0;
  display: inline-block;
}
.meta.firm {
  margin: 0 6px 0 8px;
}
.navigation {
  z-index: 20;
  margin-top: 36px;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}
.navigation__overlay {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
div.sub1:hover + .navigation__overlay,
.cbe--edit-navigation .navigation__overlay {
  background: #6b0529;
  z-index: 18;
  transform: translateZ(0);
}
.togglenavigation {
  display: none;
}
.menu {
  -webkit-tap-highlight-color: transparent;
}
.navigation .sub1 {
  z-index: 19;
  padding-bottom: 20px;
  position: relative;
  float: none;
  display: inline-block;
}
.navigation .sub1 > .item > .menu {
  -webkit-transition: none;
  transition: none;
  letter-spacing: 0.022em;
}
.navigation .sub1 > .item .path {
  color: #c4024b;
  letter-spacing: 0.01em;
}
.navigation .sub1 > .item {
  display: inline-block;
  float: none;
  z-index: 1;
  position: relative;
  text-align: left;
}
.navigation .sub1 > .init {
  margin-left: -18px;
}
.navigation .sub1 > .exit {
  margin-right: -18px;
}
.navigation .menu {
  line-height: 1.1;
  padding: 1.3em 12px 0.5em;
  font-size: 16px;
  font-size: 1.6rem;
  color: #333;
  -webkit-transition: color 0.218s;
  transition: color 0.2s;
}
.navigation .sub1:hover .menu,
.cbe--edit-navigation .navigation .menu {
  color: #999;
}
.navigation .menu:hover {
  color: #fff !important;
  -webkit-transition: none;
  transition: none;
}
.navigation .sub1:hover .item:hover .menu,
.navigation .sub1:hover .path > .sub2 .menu,
.cbe--edit-navigation .navigation .item:hover .menu,
.cbe--edit-navigation .navigation .path > .sub2 .menu {
  color: #ccc;
}
.navigation .sub1:hover a.path,
.cbe--edit-navigation .navigation a.path {
  color: #fff !important;
}
.navigation .sub2 {
  display: none;
  margin-right: -300px;
}
.navigation .sub1:hover .sub2,
.cbe--edit-navigation .navigation .sub2 {
  display: block;
}
.navigation .sub2 > .item {
  clear: left;
}
.navigation .sub2 .menu {
  font-size: 13px;
  font-size: 1.3rem;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
}
.navigation .sub2 > .exit > .menu {
  -webkit-transition-delay: 0.03s;
  transition-delay: 0.03s;
}
.navigation .sub2 .path {
  color: #fff !important;
}
.cb-mobile .navigation .sub2 .menu {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}
.footer .navi {
  display: inline-block;
  vertical-align: top;
}
.footer .sub1 {
  margin-left: -15px;
  float: none;
  margin-right: 30px;
}
@media (min-width: 900px) {
  .footer .sub1 {
    width: calc(100% - 13em - 30px);
  }
}
#view .footer .sub1 > .item {
  /* 
make them stack instead of float when too many
*/
  float: none;
  display: inline-block;
  padding-bottom: 20px;
}
.footer .menu {
  padding: 0 15px;
  font-size: 16px;
  font-size: 1.6rem;
  color: #ccc;
}
.footer .menu.menu:hover,
.footer .menu.menu:focus {
  color: #fff;
}
.footer .sub1 > .item > .menu {
  letter-spacing: 0.027em;
}
.footer .sub1 > .exit .menu {
  padding-right: 0;
}
#view .footer .sub1 > .exit {
  display: none;
}
.footer .sub2 {
  margin-top: 10px;
  margin-right: -40px;
}
.footer .sub2 .item {
  clear: left;
}
.footer .sub2 .menu {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: normal;
  font-family: 'ProximaNovaLight', 'helvetica neue', helvetica, sans-serif;
  color: #b3b3b3;
  padding-right: 0;
}
.cb-page-layout1 .main,
.cb-page-layout2 .main {
  /*
Layout 1 & 2
*/
  width: 100%;
}
.cb-page-layout1 .main > .unit,
.cb-page-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout1 .main h2,
.cb-page-layout2 .main h2,
.cb-page-layout1 .main .foot,
.cb-page-layout2 .main .foot {
  margin-right: 0.72674419%;
  margin-left: 0.72674419%;
}
.cb-page-layout1 .main .part,
.cb-page-layout2 .main .part,
.cb-page-layout1 .main > .grid table,
.cb-page-layout2 .main > .grid table {
  margin-right: 0.72674419%;
  margin-left: 0.72674419%;
  width: 98.54651163%;
}
.cb-page-layout1 .main .tiny,
.cb-page-layout2 .main .tiny {
  width: 48.54651163%;
}
.cb-page-layout1 .main > .slim,
.cb-page-layout2 .main > .slim {
  width: 50%;
}
.cb-page-layout1 .main > .slim h2,
.cb-page-layout2 .main > .slim h2,
.cb-page-layout1 .main > .slim .foot,
.cb-page-layout2 .main > .slim .foot,
.cb-page-layout1 .main > .slim .part,
.cb-page-layout2 .main > .slim .part,
.cb-page-layout1 .main > .slim.grid table,
.cb-page-layout2 .main > .slim.grid table {
  margin-right: 1.45348837%;
  margin-left: 1.45348837%;
}
.cb-page-layout1 .main > .slim .part,
.cb-page-layout2 .main > .slim .part,
.cb-page-layout1 .main > .slim.grid table,
.cb-page-layout2 .main > .slim.grid table {
  width: 97.09302326%;
}
.cb-page-layout1 .main > .slim .tiny,
.cb-page-layout2 .main > .slim .tiny {
  width: 97.09302326%;
}
.cb-page-layout3 .main {
  /*
unit to unit in large: 20px
no part margins margins but
unit margins to have easier abolute positioning of link in seam
*/
  width: 100%;
}
.cb-page-layout3 .main > .unit {
  margin-right: 1.15273775%;
  margin-left: 1.15273775%;
  width: 97.6945245%;
}
.cb-page-layout3 .main h2,
.cb-page-layout3 .main .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-page-layout3 .main .part,
.cb-page-layout3 .main > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout3 .main > .slim {
  width: 47.6945245%;
}
.cb-page-layout3 .main > .slim h2,
.cb-page-layout3 .main > .slim .foot,
.cb-page-layout3 .main > .slim .part,
.cb-page-layout3 .main > .slim.grid table {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-page-layout3 .main > .slim .part,
.cb-page-layout3 .main > .slim.grid table {
  width: 100%;
}
.cb-page-layout3 .main > .slim .tiny {
  width: 100%;
}
.cb-page-layout6 .side {
  width: 100%;
}
.cb-page-layout6 .side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout6 .side h2,
.cb-page-layout6 .side .foot {
  margin-right: 1.0989011%;
  margin-left: 1.0989011%;
}
.cb-page-layout6 .side .part,
.cb-page-layout6 .side > .grid table {
  margin-right: 1.0989011%;
  margin-left: 1.0989011%;
  width: 97.8021978%;
}
.cb-page-layout6 .side > .slim {
  width: 50%;
}
.cb-page-layout6 .side > .slim h2,
.cb-page-layout6 .side > .slim .foot,
.cb-page-layout6 .side > .slim .part,
.cb-page-layout6 .side > .slim.grid table {
  margin-right: 2.1978022%;
  margin-left: 2.1978022%;
}
.cb-page-layout6 .side > .slim .part,
.cb-page-layout6 .side > .slim.grid table {
  width: 95.6043956%;
}
.cb-page-layout6 .side > .slim .tiny {
  width: 95.6043956%;
}
.wide.form .tile .name,
.wide a.capt {
  float: left;
  width: 33%;
}
.wide.form .tile .chop,
.wide.form .tile textarea,
.wide.form .tile input.text,
.wide.form .tile .ctrl,
.wide input.capt {
  float: right;
  width: 63%;
}
.main {
  /* 
Layout 1 & 2
*/
  margin-left: -0.73746313%;
  width: 101.47492625%;
}
.cb-page-layout6 .main {
  width: 100%;
  margin-left: 0;
}
.cb-page-layout2 .main {
  /* 
large angebot (only if .cb-page-layout2 parts
have not the same 4 × @part-verticalmargin as in large)
*/
  margin-top: 7px;
}
.cb-page-layout3 .main {
  margin-left: -1.179941%;
  width: 102.35988201%;
}
.cb-page-layout6 .main {
  padding-bottom: 0;
}
.base {
  float: right;
}
.cb-page-layout6 .side.side {
  margin-left: -1.12359551%;
  width: 102.24719101%;
}
.cb-page-layout2 .area {
  margin-left: -0.73746313%;
  width: 101.47492625%;
}
.cb-page-layout4 .side,
.cb-page-layout4 .base {
  width: 48.820059%;
}
.main > .wide h2,
.north h2 {
  font-size: 28px;
  font-size: 2.8rem;
  line-height: 1.14285714;
}
.main > .wide .head {
  margin-top: 40px;
}
.main > .wide.edge .head {
  margin-top: 56px;
}
.cb-page-layout1 .main > .wide.note,
.cb-page-layout2 .main > .wide.note {
  /*
dont' center grids
*/
  text-align: center;
}
.cb-page-layout2 .main > .pure .text {
  /*
don't center text «Angebot»
*/
  text-align: left;
}
.cb-page-layout1 .main > .seam.wide,
.cb-page-layout2 .main > .seam.wide,
.main > .flat.wide,
.cb-page-layout1 .main > .edge.wide {
  /*
at least
Layout 1 & 2
*/
  margin-left: -5.20833333vw;
}
.cb-page-layout3 .main > .flat.wide {
  margin-left: -4.81770833vw;
}
/*
used in both Layout 1 & Layout 2
*/
.cb-page-layout1 .main > .pure.wide .link:only-child {
  margin-top: -10px;
  margin-bottom: 110px;
}
.cb-page-layout2 .main > .pure.wide .link:only-child {
  margin-top: 2px;
  margin-bottom: 77px;
}
/*
if changed check large layout 1 main placement
*/
.main > .slim {
  margin-top: 20px;
}
.main > .slim .pict + .lead {
  margin-top: 1.5em;
}
.cb-page-layout1 .main > .seam.wide,
.cb-page-layout2 .main > .seam.wide {
  padding-bottom: 5.4em;
  padding-bottom: 72px;
  border-bottom-width: 18px;
}
.cb-page-layout1 .main > .seam.wide .head,
.cb-page-layout2 .main > .seam.wide .head {
  margin-bottom: 20px;
  margin-top: 56px;
}
#view .cb-page-layout1 .main > .seam.wide .body,
#view .cb-page-layout2 .main > .seam.wide .body {
  max-width: 88.28125%;
  float: none;
  margin-right: auto;
  margin-left: auto;
}
.csscolumns #view .cb-page-layout1 .main > .seam.wide .text,
.csscolumns #view .cb-page-layout2 .main > .seam.wide .text {
  max-width: none;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
.cb-page-layout1 .main > .seam.wide + .wide,
.cb-page-layout2 .main > .seam.wide + .wide {
  margin-top: 24px;
}
/*
using modernizer
*/
.csscolumns #view .cb-page-layout1 .main > .seam.wide .body,
.csscolumns #view .cb-page-layout2 .main > .seam.wide .body {
  text-align: left;
  -webkit-columns: 2 19em;
  -webkit-column-gap: 20px;
  -moz-columns: 2 19em;
  -moz-column-gap: 20px;
  columns: 2 19em;
  column-gap: 20px;
}
.csscolumns #view .cb-page-layout1 .main > .seam.wide .part,
.csscolumns #view .cb-page-layout2 .main > .seam.wide .part {
  margin-top: 0;
  margin-bottom: 0.8em;
}
/*
dimensions largely taken from large (!SSIIIIIICC)
*/
.main > .flat.wide {
  /*
padding-bottom inconsistent,
taken from layout2 assuming «Angebot zählt»
*/
  padding-top: 0;
  padding-bottom: 87px;
  margin-bottom: 74px;
}
.cb-page-layout1 .main > .flat.wide {
  padding-bottom: 73px;
  margin-bottom: 10px;
}
.main > .flat.wide .head {
  margin-top: 70px;
}
.main > .flat.wide h2 {
  font-size: 28px;
  font-size: 2.8rem;
  line-height: 1.14285714;
  /*
max-width for layout2 large
*/
  margin-right: auto;
  margin-left: auto;
  max-width: 24em;
}
.main > .flat.wide .part {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}
.main > .flat.wide .norm {
  max-width: 64em;
  margin-right: auto;
  margin-left: auto;
}
.main > .flat.wide .loud {
  max-width: 34em;
  margin-right: auto;
  margin-left: auto;
  line-height: 1.71428571;
}
.main > .flat.wide .link {
  margin-top: 2.6em;
}
.main > .flat.wide .link > .open {
  padding: 1.1em 1.9em;
  letter-spacing: 0.23em;
}
/*
.show has aspect in small -> large
.show is above content in small & medium
.show is background of unit in large
*/
.cb-page-layout1 .main > .edge.wide h3 {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.34615385;
  position: relative;
}
.cb-page-layout1 .main {
  text-align: center;
}
#view .cb-page-layout1 .main > .slim {
  display: inline-block;
  float: none;
  vertical-align: top;
  text-align: left;
}
#view .cb-page-layout1 .main > .slim + .wide {
  display: inline-block;
  float: none;
  vertical-align: top;
}
.cb-page-layout3 .main h3 {
  font-size: 20px;
  font-size: 2rem;
}
.cb-page-layout3 .main > .seam .link > .open {
  font-size: 28px;
  font-size: 2.8rem;
}
.cb-page-layout3 .main > .edge h3 {
  font-size: 28px;
  font-size: 2.8rem;
  line-height: 1.5;
}
.cb-page-layout3 .main > .edge .tiny.text {
  width: 60% !important;
}
.cb-page-layout3 .main > .edge .tiny.link {
  width: 39% !important;
  float: right;
}
.cb-page-layout3 .main > .edge .link > .open {
  float: right;
  margin-top: 1.5em;
}
.cb-page-layout4 .side > .unit,
.cb-page-layout4 .base > .unit {
  font-size: 20px;
  font-size: 2rem;
}
.cb-page-layout4 .side > .seam h2,
.cb-page-layout4 .base > .seam h2 {
  font-size: 24px;
  font-size: 2.4rem;
}
.cb-page-layout6 .main > .unit {
  margin-bottom: 42px;
}
.cb-page-layout6 .side > .unit {
  margin-bottom: 45px;
}
#view .cb-page-layout6 .side > .unit {
  display: inline-block;
  float: none;
  vertical-align: top;
}
.fake-codepart {
  margin-top: 40px;
  margin-bottom: 40px;
}
.north {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.625;
}
.cb-page-layout1 .north > .unit {
  padding-bottom: 20px;
}
@media (max-width: 899px) {
  .north .body {
    width: 90%;
    margin-left: 5%;
  }
}
.north.north h2 {
  font-size: 28px;
  font-size: 2.8rem;
}
.north h3 {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.33079848;
}
.north .link > .open {
  padding: 1em 1.5em 0.8em;
  letter-spacing: 0.14em;
}
#mc_embed_signup .mc-field-group,
#mc_embed_signup .button {
  display: inline-block;
  width: 25%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
#disp.shop,
#disp.cb-shoppingcart {
  left: 15%;
  margin-left: 0;
  width: 70%;
}
/******* module-shop-medium.less 2013-1-16 *******/
.cb-shop-tabs > li {
  display: inline-block;
  margin-bottom: -1px;
}
.cb-shop-tab {
  margin-right: 0.3em;
  border-bottom-width: 1px;
  border-radius: 4px 4px 0 0;
}
.cb-shop-tab.selected {
  border-bottom-color: #f5f5f5;
}
#disp.shop .name,
a.agbs,
.cb-shop-country > label {
  width: 37%;
}
#disp.shop .file,
#disp.shop .text,
#disp.shop select {
  width: 62%;
}
#disp.shop .zip {
  clear: none;
  width: 10%;
}
#disp.shop input.city {
  width: 51%;
}
#disp.shop .cb-shop-currency > select {
  width: 55%;
}
.cb-shop-currency > select + a {
  width: 6%;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a {
  clear: none;
  margin-bottom: 0.5em;
}
#disp .cb-shop-country > select {
  width: 62%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart .ship,
.cb-shoppingcart fieldset.tiny {
  float: left;
  width: 49%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart fieldset.tiny {
  margin-right: 2%;
}
/*# sourceMappingURL=./screen-medium.css.map */